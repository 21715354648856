.Button {
  position: relative;

  &[data-size="xsmall"] .ButtonContentLoading,
  &[data-size="small"] .ButtonContentLoading {
    width: 18px;
    height: 18px;
  }

  &[data-size="medium"] .ButtonContentLoading {
    width: 26px;
    height: 26px;
  }

  &[data-size="large"] .ButtonContentLoading {
    width: 28px;
    height: 28px;
  }
}

.ButtonContentLoading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Button:not([data-variant="plain"]) {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  border: 0;
  display: flex;
  align-items: center;
  padding: var(--spacing-4) var(--spacing-5);
  border-radius: var(--border-radius-md);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-text-base);
  transition: all 200ms ease-in-out;
  cursor: pointer;

  // Configurable parameters per variant
  background-color: var(--button-bg-color);
  color: var(--button-text-color);

  &:hover:not(:disabled),
  &:focus:not(:disabled),
  &:active:not(:disabled) {
    background-color: var(--button-bg-color-active);
    color: var(--button-text-color-active);
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &[data-size="xsmall"] {
    padding: var(--spacing-2) var(--spacing-3\.5);
    font-size: var(--font-size-text-sm);
  }

  &[data-size="small"] {
    padding: var(--spacing-2\.5) var(--spacing-3);
    font-size: var(--font-size-text-sm);
  }

  &[data-size="medium"] {
    padding: var(--spacing-3\.5) var(--spacing-4\.5);
    font-size: var(--font-size-text-base);
  }

  &[data-size="large"] {
    padding: var(--spacing-4) var(--spacing-5);
    font-size: var(--font-size-text-base);
  }

  &[data-variant="primary"] {
    --button-bg-color: var(--color-secondary-500);
    --button-text-color: var(--color-secondary-contrast-500);
    --button-bg-color-active: var(--color-secondary-900);
    --button-text-color-active: var(--color-secondary-contrast-900);
  }

  &[data-variant="secondary"] {
    --button-bg-color: var(--color-primary-500);
    --button-text-color: var(--color-primary-contrast-900);
    --button-bg-color-active: var(--color-primary-900);
    --button-text-color-active: var(--color-primary-contrast-900);
  }

  &[data-variant="ghost"] {
    --button-bg-color: var(--color-white);
    --button-text-color: var(--color-primary-contrast-500);
    --button-bg-color-active: var(--color-secondary-500);
    --button-text-color-active: var(--color-secondary-contrast-900);
  }

  &[data-variant="danger"] {
    --button-bg-color: var(--color-red-500);
    --button-text-color: var(--color-red-contrast-500);
    --button-bg-color-active: var(--color-red-800);
    --button-text-color-active: var(--color-red-contrast-800);
  }
}

.Button[data-variant="plain"] {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: transparent;
  color: inherit;
  margin: inherit;
  padding: inherit;
  line-height: inherit;
  font-family: inherit;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &[data-size="xsmall"],
  &[data-size="small"] {
    width: 18px;
    height: 26px;
  }
}

.Button[data-variant="primaryOutline"] {
  background-color: transparent;
  border: 1px solid var(--color-secondary-500);
  border-radius: var(--border-radius-base);
  color: var(--color-secondary-500);
  font-style: normal;
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  &:hover:not(:disabled),
  &:focus:not(:disabled),
  &:active:not(:disabled) {
    color: var(--color-secondary-contrast-900);
    background-color: var(--color-secondary-500);
  }
}

.Button[data-variant="secondaryOutline"] {
  background-color: transparent;
  border: 1px solid var(--color-primary-500);
  border-radius: var(--border-radius-base);
  color: var(--color-primary-500);
  font-style: normal;
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  &:hover:not(:disabled),
  &:focus:not(:disabled),
  &:active:not(:disabled) {
    color: var(--color-primary-contrast-900);
    background-color: var(--color-primary-500);
  }
}

.Button[data-variant="dangerOutline"] {
  background-color: transparent;
  border: 1px solid var(--color-red-500);
  border-radius: var(--border-radius-base);
  color: var(--color-red-500);
  font-style: normal;
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  &:hover:not(:disabled),
  &:focus:not(:disabled),
  &:active:not(:disabled) {
    color: var(--color-primary-contrast-900);
    background-color: var(--color-red-500);
  }
}

.ButtonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  gap: var(--spacing-2);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ButtonLoading .ButtonContent {
  visibility: hidden;
}

.ButtonContentLoading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
